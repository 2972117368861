$(document).ready(function () {

  if (window.coreFunctions.getCookie('cookie') !== 'true') {
    $('.cookie').show();
    $('.js--cookie').on('click', function () {
      window.coreFunctions.setCookie('cookie','true','','/')
      $('.cookie').hide();
      return false;
    });
  }

  $(document).ready(function () {
    $('.js--tab').on('click', function () {
      $('.main-block__tab1').hide();
      $('.main-block__tab2').show();
      return false;
    });
  });

  let $body = $('body');

  // events on body
  $body
    .addClass('loaded')
    .on('click', '*[data-gtagcategory]', function (e) {
      // gtag
      var data = $(this).data();
      if (typeof gtag === 'undefined') {
        return false;
      }
      gtag('event', 'click', {
        'event_category': data.gtagcategory,
        'event_label': data.gtaglabel
      });
    });

  // inputmask
  $('input[data-mask]').each(function () {
    let $t = $(this), mask = $t.data('mask');
    if (typeof $t.attr('disabled') === 'undefined') {
      $t.inputmask('remove').inputmask('' + mask);
    }
  });

  // datepicker
  $.datepicker.regional['ru'] = {
    closeText: 'Закрыть',
    prevText: 'Предыдущий',
    nextText: 'Следующий',
    currentText: 'Сегодня',
    monthNames: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
    monthNamesShort: ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'],
    dayNames: ['воскресенье', 'понедельник', 'вторник', 'среда', 'четверг', 'пятница', 'суббота'],
    dayNamesShort: ['вск', 'пнд', 'втр', 'срд', 'чтв', 'птн', 'сбт'],
    dayNamesMin: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
    weekHeader: 'Не',
    dateFormat: 'dd.mm.yy',
    firstDay: 1,
    isRTL: false,
    changeMonth: true,
    changeYear: true,
    showMonthAfterYear: false,
    yearSuffix: ''
  };
  $.datepicker.setDefaults($.datepicker.regional['ru']);
  $('.datepicker').each(function () {
    let $t = $(this);
    $t.datepicker({
      yearRange: $t.attr('data-year-range')
    });
  });

  // selector
  (function () {
    let language = 'RU',
      country = 'RUS',
      project = 'ANY',
      token = 'Bearer x74Ff8CF_QWYu_ltlFdsMH2xW3f2WKlr',
      widgetOptions = {},
      wrapperInstance,
      widgetInited = false;


    $('.js--selector').on('click', function (e) {
      e.preventDefault();

      let modalSelector = '.modal--model', $modal = $(modalSelector),
        model = $(this).attr('data-modal-for'),
        dataset = {
          "type": "ez", // technology of the selector
          "value": model // name of the selector
        }
      if (model === 'LXM32_RUS') {
        dataset = {
          "type": "hi", // technology of the selector
          "value": model // name of the selector
        }
      }
      wrapperInstance = seWidgetWrapper.startWidget(dataset, language, country, project, token, widgetOptions);
      // let wrapperInstance = seWidgetWrapper.startWidget({"type":"ez","value":"Altivar 12"}, language, country, project, token, widgetOptions);

      if (!widgetInited) {
        wrapperInstance.addSaveConfigurationListener(function (event) {
          console.info('-------------------------------');
          console.info('onSaveConfiguration');
          console.info('Products:', event.products); // array of selected products
          console.info('-------------------------------');

          $('#modal-feedback #feedback_models').val(JSON.stringify(event.products));
          //$modal.find('.modal__button').show();
          jewelryModals.show('.modal--form');
        });
      }

      widgetInited = true;

      jewelryModals.show(modalSelector);
    });
  })();
});
